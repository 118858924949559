<template>
    <div>
      <b-card no-body>
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <b-col cols="12" md="3" lg="3" sm="6" class="mb-2 ipad">
              <b-form-group :label="$t('From date')" label-for="fromDate">
                <flat-pickr
                    id="fromDate"
                    v-model="search.fromdate"
                    class="form-control"
                    placeholder="YYYY-MM-DD"
                />
                <div style="margin: 8px 0px; color: red" v-if="isFromDate">
                  The field is require
                </div>
              </b-form-group>
            </b-col>
  
            <b-col cols="12" md="3" lg="3" sm="6" class="mb-2 ipad">
              <b-form-group :label="$t('To date')" label-for="toDate">
                <flat-pickr
                    id="toDate"
                    v-model="search.todate"
                    class="form-control"
                    placeholder="YYYY-MM-DD"
                />
                <div style="margin: 8px 0px; color: red" v-if="isToDate">
                  The field is require
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="d-flex align-items-end justify-content-end">
            <b-col
                cols="12"
                md="4"
                class="d-flex align-items-end justify-content-end"
            >
              <b-button variant="outline-primary" @click="resetFilter()">
                Reset
              </b-button>
              <b-button variant="primary" class="ml-2" @click="searchFilter()">
                Search
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-card no-body>
        <b-table
            ref="refSportTeamListTable"
            class="position-relative table-white-space mb-0 max-height-table"
            sticky-header
            head-variant="light"
            :items="dataAccountWinLoss"
            responsive
            :fields="tableColumns"
            primary-key="user_id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(bet)="data">
            {{ numberFormat(data.item.bet / 1000) }}
          </template>
  
          <template #cell(win)="data">
            {{ numberFormat(data.item.win / 1000) }}
          </template>
  
          <template #cell(lost)="data">
            {{ numberFormat(data.item.lost / 1000) }}
          </template>
  
          <template #cell(cancel)="data">
            {{ numberFormat(data.item.cancel / 1000) }}
          </template>
  
          <template #cell(tie)="data">
            {{ numberFormat(data.item.tie / 1000) }}
          </template>

          <template #cell(betValid)="data">
            {{ numberFormat(data.item.betValid) }}
          </template>

          <template #cell(count_bet)="data">
            {{ numberFormat(data.item.count_bet) }}
          </template>
  
          <template #cell(count_win)="data">
            {{ numberFormat(data.item.count_win) }}
          </template>
  
          <template #cell(count_lost)="data">
            {{ numberFormat(data.item.count_lost) }}
          </template>
  
          <template #cell(count_cancel)="data">
            {{ numberFormat(data.item.count_cancel) }}
          </template>
  
          <template #cell(count_tie)="data">
            {{ numberFormat(data.item.count_tie) }}
          </template>

          <template #cell(countBetValid)="data">
            {{ numberFormat(data.item.countBetValid) }}
          </template>
  
          <template #cell(winLoss)="data">
            <span v-if="data.item.winLoss > 0" class="text-primary">
              {{ numberFormat(data.item.winLoss) }}
            </span>
            <span v-if="data.item.winLoss <= 0" class="text-danger">
              {{ numberFormat(data.item.winLoss) }}
            </span>
          </template>

          <template #cell(action)="data">
            <b-button
              v-if="$can('read','agentwinlossgeneraldetail')"
              v-b-tooltip.hover.v-info
              :title="$t('Xem chi tiết')"
              variant="primary"
              class="btn-icon btn-sm mr-50"
              @click="viewDetail(data.item)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </template>
        </b-table>
        <div class="p-2">
          <b-row>
            <b-col
                cols="12"
                sm="6"
                class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span class="text-muted"
              >Showing {{ dataMeta.of }} entries</span
              >
            </b-col>
          </b-row>
        </div>
      </b-card>
      <win-loss-general-detail-modal
        v-if="winlossDetail && winlossDetail.product_id"
        :winloss-detail.sync=winlossDetail
        :from-date.sync="fromDateFilter"
        :to-date.sync="toDateFilter"
        @refetch-data="refetchData"
      />
    </div>
  </template>
  
  <script>
  import {
    BButton,
    BModal,
    VBModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BImg,
    VBTooltip
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  import vSelect from "vue-select";
  import {ref, watch, computed, onUnmounted} from "@vue/composition-api";
  import {formatDateString, numberFormat} from "@core/utils/filter";
  import {useToast} from "vue-toastification/composition";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import store from "@/store";
  import baseUrl from "@/libs/base-url";
  import sportsStoreModule from "@/views/sports/sportsStoreModule";
  import AddSportsTeamModal from "@/views/sports/AddSportsTeam.vue";
  import UpdateSportTeamModal from "@/views/sports/UpdateSportsTeam.vue";
  import flatPickr from "vue-flatpickr-component";
  import agentStoreModule from "@/views/agent/agentStoreModule";
  
  export default {
    components: {
      WinLossGeneralDetailModal: () => import("./WinLossGeneralDetailModal.vue"),
      flatPickr,
      BButton,
      BFormGroup,
      BModal,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BTable,
      BTr,
      BTh,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      vSelect,
      BImg,
    },
    directives: {
      "b-modal": VBModal,
      "b-tooltip": VBTooltip,
      Ripple,
    },
    data() {
      return {
        winlossDetail: null,
      };
    },
    methods: {
      viewDetail(datas) {
        this.winlossDetail = datas;
        this.$bvModal.show("modal-winloss-general-detail");
      },
    },
    setup() {
      if (!store.hasModule("agent"))
        store.registerModule("agent", agentStoreModule);
      if (!store.hasModule("sports"))
        store.registerModule("sports", sportsStoreModule);
      onUnmounted(() => {
        if (store.hasModule("agent")) store.unregisterModule("agent");
      });
  
      const toast = useToast();
  
      const search = ref({
        username: "",
        sport_type_id: "",
        fromdate: formatDateString(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)),
        todate: formatDateString(new Date()),
      }); 
      const resolveTypeStatus = (status) => {
        if (status === 1) return {label: "place bet", variant: "success"};
        if (status === 2) return {label: "win", variant: "secondary"};
        if (status === 3) return {label: "loss", variant: "Primary"};
        if (status === 4) return {label: "cancel", variant: "Warning"};
        if (status === 5) return {label: "tie", variant: "Danger"};
        return {label: "None", variant: "secondary"};
      };
      // Status: 1:place bet, 2: win 3: loss, 4:cancel, 5: tie (lưu í nếu status nào k có thì hiển thị trống)
      const toDateFilter = ref(formatDateString(new Date()));
      const fromDateFilter = ref(formatDateString(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)));
      const codeProduct = ref([]);
      const perPage = ref(25);
      const totalSportTeam = ref(0);
      const currentPage = ref();
      const sortBy = ref("id");
      const isSortDirDesc = ref(true);
  
      const refSportTeamListTable = ref(null);
      const isFromDate = ref(false);
      const isToDate = ref(false);
      const tableColumns = [
        {key: "index", label: "#", sortable: false},
        {key: "name", label: "Name", sortable: true},
        {key: "bet", label: "Bet", sortable: true},
        {key: "count_bet", label: "Count Bet", sortable: true},
        {key: "betValid", label: "Bet Valid", sortable: true},
        {key: "countBetValid", label: "Count Bet Valid", sortable: true},
        {key: "win", label: "Win", sortable: true},
        {key: "count_win", label: "Count Win", sortable: true},
        {key: "lost", label: "Loss", sortable: true},
        {key: "count_lost", label: "Count Loss", sortable: true},
        {key: "cancel", label: "Cancel", sortable: true},
        {key: "count_cancel", label: "Count Cancel", sortable: true},
        {key: "tie", label: "Tie", sortable: true},
        {key: "count_tie", label: "Count Tie", sortable: true},
        {key: "winLoss", label: "Win/Loss", sortable: true},
        {key: "action", label: "Action", sortable: true},
      ];
  
      const refetchData = () => {
        refSportTeamListTable.value.refresh();
      };
  
      watch(
          [currentPage],
          () => {
            refetchData();
          }
      );
      const dataVendorWinLoss = ref();
      const transformedArray = ref();
      const sportTeamLists = (ctx, callback) => {
        store
            .dispatch("agent/fetchWinLossGeneral", {
              fromDate: fromDateFilter.value,
              toDate: toDateFilter.value
            })
            .then((response) => {
              let result = response.data.data;
              perPage.value = response.pageSize;
              dataVendorWinLoss.value = Object.values(result).map((item, index) => {
                let winLoss = item.win/1000 + item.cancel/1000 + item.tie/1000 - item.bet/1000;
                let betValid = item.bet/1000 - item.cancel/1000;
                let countBetValid = item.count_bet - item.count_cancel;
                return {...item, winLoss: winLoss, betValid: betValid, countBetValid: countBetValid, index: index + 1}
              });
              totalSportTeam.value = dataVendorWinLoss.value.length;
            });
      };
      // sportTeamLists();
  
      const dataMeta = computed(() => {
        return {
          of: totalSportTeam.value,
        };
      });

      sportTeamLists();
  
      const resetFilter = () => {
        search.value.fromdate = "";
        search.value.todate = "";
        toDateFilter.value = null;
        fromDateFilter.value = null;
        sportTeamLists();
      };
  
      const searchFilter = () => {
        isFromDate.value = false;
        isToDate.value = false;
        if (search.value.fromdate === "" || !search.value.fromdate) {
          isFromDate.value = true;
        }
        if (search.value.todate === "" || !search.value.todate) {
          isToDate.value = true;
        }
  
        if (search.value.fromdate !== "" && search.value.todate !== ""
        ) {
          isFromDate.value = false;
          isToDate.value = false;
          toDateFilter.value = search.value.todate;
          fromDateFilter.value = search.value.fromdate;
          sportTeamLists();
        }
      };
  
      return {
        tableColumns,
        perPage,
        currentPage,
        totalSportTeam,
        dataMeta,
        sortBy,
        isSortDirDesc,
        refSportTeamListTable,
        sportTeamLists,
        refetchData,
        toDateFilter,
        search,
        resetFilter,
        numberFormat,
        searchFilter,
        baseUrl,
        fromDateFilter,
        isFromDate,
        isToDate,
        dataAccountWinLoss: dataVendorWinLoss,
        resolveTypeStatus,
        transformedArray,
      };
    },
  };
  </script>
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  </style>
  